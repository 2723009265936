$mat-border-color: #5E5E5E;


.mat-form-field-appearance-outline.mat-form-field-hide-placeholder .mat-form-field-outline-gap {
  border-top: 1px solid $mat-border-color !important;
  border-bottom: 1px solid $mat-border-color !important;
}

.mat-form-field-appearance-outline {

  & .mat-form-field-outline-gap {
    border-bottom: 1px solid $mat-border-color !important;
  }

  & .mat-form-field-outline-start {
    border-left: 1px solid $mat-border-color !important;
    border-bottom: 1px solid $mat-border-color !important;
    border-top: 1px solid $mat-border-color !important;
  }

  & .mat-form-field-outline-end {
    border-right: 1px solid $mat-border-color !important;
    border-bottom: 1px solid $mat-border-color !important;
    border-top: 1px solid $mat-border-color !important;
  }

}

.mat-form-field {
  width: 100%;
}

mat-progress-bar.e-progress-bar {
  position: fixed;
  top: 0;
  z-index: 999;
}

.mat-progress-bar-buffer{
  background-color: #c7bb9e !important;
}

.mat-progress-bar-fill:after{
  background-color: var(--ion-color-primary) !important;
}

.mat-form-field-wrapper {
  padding-bottom: 5px !important;
}

.mat-input-element:disabled {
  color: $mat-border-color !important;
}

.mat-tab-group.mat-primary .mat-ink-bar{
  background-color: var(--ion-color-primary) !important;
}

.mat-tab-body-content{
  font-family: 'Source Sans Pro', sans-serif !important;
  width: 100% !important;
  overflow: hidden !important;
}


.mat-dialog-container{
  font-family: 'Source Sans Pro', sans-serif !important;
  background: #131313 !important;
  margin: 0 auto;
}

.mat-checkbox {

  & .mat-checkbox-inner-container {
    width: 20px !important;
    height: 20px !important;
  }

  & .mat-checkbox-frame {
    border-color: #ACACAC;
    border-width: 1px;
    border-radius: 4px !important;
  }

}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--ion-color-primary);
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent.mat-checkbox-disabled .mat-checkbox-background {
  background-color: #333;
}

.mat-checkbox-checkmark-path {
  stroke-width: 3px !important;
  stroke: #fff !important;
}

.mat-radio-outer-circle {
  border-width: 1px !important;
  border-color: #ACACAC !important;
}

.mat-radio-button.mat-primary.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-width: 6px !important;
}

.mat-radio-button.mat-primary.mat-primary.mat-radio-checked .mat-radio-inner-circle {
  background: #fff;
}
