@import "assets/style/variables";
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap');

@import 'swiper/swiper-bundle.min.css';
@import 'ngx-toastr/toastr';
@import "assets/scss/material-extend";

@import 'bootstrap/dist/css/bootstrap.min.css';
@import "animate.css/animate.min.css";

* {
  font-family: 'Source Sans Pro', sans-serif !important;
}

body {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  background: #141413;
  color: #fff;
}

.j-font {
  font-family: 'Source Sans Pro', sans-serif !important;
}

div, p, a, span, h1, h2, h3, h4, h5 {
  color: #fff;
}

a:hover {
  color: var(--ion-color-primary) !important;
}

.main{
  transition: all 0.5s ease;
}


$sizes: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45,
46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60;
$weights: 100, 400, 300, 600, 700;

@each $size in $sizes {
  .text-#{$size} {
    font-size: #{$size}px !important;
  }

  .p-top-#{$size} {
    top: #{$size}px !important;
  }
  .p-bottom-#{$size} {
    bottom: #{$size}px !important;
  }
  .p-left-#{$size} {
    left: #{$size}px !important;
  }
  .p-right-#{$size} {
    right: #{$size}px !important;
  }

  .m-#{$size} {
    margin: #{$size}px !important;
  }
  .mt-#{$size} {
    margin-top: #{$size}px !important;
  }
  .mb-#{$size} {
    margin-bottom: #{$size}px !important;
  }
  .ml-#{$size} {
    margin-left: #{$size}px !important;
  }
  .mr-#{$size} {
    margin-right: #{$size}px !important;
  }
  .p-#{$size} {
    padding: #{$size}px !important;
  }
  .pt-#{$size} {
    padding-top: #{$size}px !important;
  }
  .pb-#{$size} {
    padding-bottom: #{$size}px !important;
  }
  .pl-#{$size} {
    padding-left: #{$size}px !important;
  }
  .pr-#{$size} {
    padding-right: #{$size}px !important;
  }

  .lh-#{$size} {
    line-height: #{$size}px !important;
  }

  .g-#{$size} {
    gap: #{$size}px !important;
  }
}

@each $weight in $weights {
  .fw-#{$weight} {
    font-weight: $weight !important;
  }
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'sans-serif' !important;
}

ion-tab-button[aria-selected=true] {
  color: var(--primary-color);
}

.lighter {
  font-weight: lighter !important;
}

.bolder {
  font-weight: 600 !important;
}

.semi-bolder {
  font-weight: 600 !important;
}

.pointer {
  cursor: pointer !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.no-decoration {
  text-decoration: none !important;
}

.header-md:after {
  background-image: none !important;
}

.header-ios ion-toolbar:last-child {
  --border-width: 0;
}

.animation-all {
  transition: all 0.5s ease;
}

.red-text {
  color: #d33939 !important;
}

.red {
  background: #d33939 !important;
}

.blue-text {
  color: #1a459f !important;
}

.blue {
  background: #1a459f !important;
}

.light-text {
  color: var(--ion-color-dark) !important;
}

.lightyellow-text {
  color: #FCE394;
}

.lightyellow {
  background: #FCE394;
}

.yellow-text {
  color: var(--ion-color-primary) !important;
}

.yellow {
  background: var(--ion-color-primary) !important;
}

.preorder {
  background-color: #AFBCFF !important;
}

.preorder-text {
  color: #AFBCFF;
}

.darkgreen-text {
  color: #485F40;
}

.darkgreen {
  background: #485F40 !important;
}

.darkgreen-btn {
  --background: #485F40 !important;
}

.tertiary {
  background: var(--ion-color-tertiary);
}

.tertiary-text {
  color: var(--ion-color-tertiary) !important;
}

.lightgreen {
  background: #E6EDE4;
}

.lightgreen-text {
  color: #E6EDE4;
}

.green-text {
  color: #08D85B !important;
}

.green {
  background-color: #08D85B;
}

.lightning {
  background: #ffeed4 !important;
}

.lightning-text {
  color: #ffeed4 !important;
}

.white-text {
  color: #fff !important;
}

.white {
  background-color: #fff !important;
}

.cyan-text {
  color: #AFBCFF;
}

.cyan {
  background-color: #AFBCFF;
}

.grey-text {
  color: #ACACAC !important;
}

.grey {
  background-color: #ACACAC !important;
}

.matgrey {
  background: #2B2B2B !important;
}

.matgrey-text {
  color: #2B2B2B;
}

.transparent {
  background-color: transparent !important;
}

.darkgrey-text {
  color: #5D6D77 !important;
}

.black {
  background-color: #141413 !important;
}

.black-text {
  color: #141413 !important;
}

.black-light {
  background-color: #1e1e1e !important;
}

.black-light-text {
  color: #1e1e1e !important;
}

.darkgrey {
  background-color: #5D6D77 !important;
}

.lightgrey-text {
  color: #F2F4F5 !important;
}

.lightgrey {
  background-color: #F2F4F5 !important;
}

.lightblue {
  background: #EDF7FD;
}

.neutral-grey {
  background: #5E5E5E;
}

.neutral-grey-text {
  color: #5E5E5E;
}

.award-text {
  color: #E05284 !important;
}

.award {
  background: #E05284 !important;
}

.award-btn {
  --background: #E05284 !important;
}


.w100 {
  width: 100%;
}

.shadow {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.radius0 {
  border-radius: 0px !important;
}

.radius4 {
  border-radius: 4px;
}

.radius8 {
  border-radius: 8px;
}

.radius10 {
  border-radius: 10px;
}

.justify {
  text-align: justify;
}

.text-left {
  text-align: left;
}

ion-title, ion-back-button {
  color: #5D6D77;
}

ion-button {
  --border-radius: 10px;
  --box-shadow: none;
  height: 48px;
}

.item-interactive.ion-invalid {
  --highlight-background: #ccc;
}

.item-interactive.ion-valid {
  --highlight-background: #ccc;
}

.item-fill-outline {
  --border-color: #ccc;
}

ion-input {
  --padding-bottom: 0px !important;

  & .item-native {
    border-radius: 10px;
  }

}


.btn {
  font-size: 16px;
  height: 42px;
  border-radius: 8px !important;
  font-weight: 600 !important;
}

.btn-tertiary {
  color: #fff;
  border-color: var(--ion-color-tertiary);
  background: var(--ion-color-tertiary);

  &:hover {
    color: #fff;
    background: #5F6FC470;
  }

}

.btn-jundo {
  color: #141414 !important;
  border-color: var(--ion-color-primary);
  background: var(--ion-color-primary);

  &:hover {
    color: #141414 !important;
    border-color: var(--ion-color-primary-shade);
    background: var(--ion-color-primary-shade) !important;
  }

  &.small {
    padding: 6px;
    height: 30px;
  }

  &:disabled {
    background: var(--ion-color-primary-shade) !important;
  }
}

.btn-outline-jundo {
  font-size: 16px;
  color: #fff !important;
  border: 2px solid var(--ion-color-primary);
  background: transparent !important;

  &:hover {
    color: #fff !important;;
    border-color: var(--ion-color-primary);
    background: transparent !important;
  }

  &:disabled {
    color: #a5a5a5 !important;
    border: 2px solid var(--ion-color-primary);
    background: transparent !important;
  }
}

.btn-link-jundo {
  color: #fff;
  border: none;
  background: transparent !important;

  &:hover {
    color: rgba(255, 255, 255, 0.62);
  }
}

.btn-facebook {
  color: #fff !important;
  border-color: #3B5998;
  background: #3B5998;

  &:hover {
    color: #fff !important;
    border-color: #445880;
    background: #445880 !important;
  }
}

.btn-darkgreen {
  color: #fff;
  border-color: #485F40;
  background: #485F40;

  &:hover {
    color: #fff;
    background: #485F40;
  }

}

.btn-primary {
  color: #fff !important;
}

.text-right {
  text-align: right !important;
}

.toast-title {
  font-weight: 400;
}

.toast-container .ngx-toastr {
  box-shadow: none;
}


.mat-form-field-subscript-wrapper {
  position: relative !important;
}

.dropdown-menu {
  z-index: 9999;
}


.main {
  min-height: 100vh;

  &.fixed {
    position: fixed;
  }

}

.loading-text {
  top: 56%;
}


mat-drawer {
  width: 500px !important;
}

.cursor-default {
  cursor: default !important;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Supported by Chrome, Edge, Opera and Firefox */
}

.border-active {
  border: 2px solid var(--ion-color-primary);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6b6b6b;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(70, 70, 70, 0.57);
}

.swiper-button-next {
  background: linear-gradient(90deg, rgba(20, 20, 19, 0) 0%, rgba(20, 20, 19, 0.9) 100%);
  border-radius: 0 0 4px 4px;
  color: #fff !important;
  height: 100%;
  width: 80px;
  right: 0;
  top: 0;
  margin-top: 0;

  &.swiper-button-disabled {
    display: none;
  }

  &:after {
    margin-right: -40px;
    font-size: 30px;
  }

}

.swiper-button-prev {
  background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(20, 20, 19, 0.9) 100%);
  color: #fff !important;
  height: 100%;
  width: 80px;
  left: 0;
  top: 0;
  margin-top: 0;

  &.swiper-button-disabled {
    display: none;
  }

  &:after {
    margin-left: -40px;
    font-size: 30px;
  }
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--ion-color-primary) !important;
}


.progress-box-container {
  width: 150px;
  margin: 0 auto 30px auto;

  & .progress-box {
    width: 100%;
    height: 2px;
    border-radius: 30px;
    margin-top: 16px;
    background: #fff !important;

    &.active {
      height: 4px;
      margin-top: 15px;
      background: var(--ion-color-primary) !important;
    }

  }

}

.sticky {
  position: sticky;
  top: 60px;
  z-index: 9;
}

.show-on-device {
  display: none;
}

.table {
  & > :not(caption) > * > * {
    background: #141414;
  }
}

.table-hover {
  > tbody > tr:hover > * {
    color: var(--ion-color-primary);
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, .82) !important;
}

.mat-expansion-panel {
  border-radius: 8px !important;
}

.mat-expansion-indicator {
  margin-top: -5px;

  &::after {
    padding: 5px !important;
    margin-top: -2px;
  }
}

.mat-expansion-panel-header {
  font-size: 16px !important;
}

.mat-expansion-panel-header-description, .mat-expansion-indicator:after {
  color: white !important;
}

tr.mat-row, tr.mat-footer-row, tr.mat-header-row {
  height: 40px !important;
}

.alert-jundo {
  background: rgba(247, 189, 4, 0.1);
}

.alert-icon-trash {
  width: 17px;
  margin-top: -5px;
  margin-right: 5px;
}

.row-is-clicked {
  background: rgba(247, 189, 4, 0.05) !important;
}

.ql-active {
  color: var(--ion-color-primary) !important;
}

.mat-pseudo-checkbox {
  color: #fff !important;
}

.mat-sort-header-content {
  color: var(--ion-color-primary) !important;
}

@media screen and (max-width: 600px) {
  .hide-on-device {
    display: none;
  }
  .show-on-device {
    display: inline;
  }
}

@media screen and (max-width: 900px) {
  .swiper-button-next, .swiper-button-prev {
    display: none;
  }
}

.fc-col-header-cell-cushion {
  text-decoration: none !important;
  color: var(--ion-color-primary) !important;
}

.fc-daygrid-day-number {
  text-decoration: none !important;
}

.fc-popover-header, .fc-popover-body {
  background-color: #141413 !important;
}

.fc-theme-standard td, .fc-theme-standard th {
  border: 1px solid #dddddd38 !important;
}

.fc-theme-standard .fc-scrollgrid {
  border: 1px solid #dddddd38 !important;
}

.fc-daygrid-event {
  padding-left: 5px;
  cursor: pointer;
}

.mat-calendar-header {
  background: var(--ion-color-primary) !important;
}

ol.j, ul.j {
  padding-left: 1rem !important;
}

// calendar
.fc-daygrid-event, .fc-daygrid-more-link {
  margin-left: 10px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.smartbanner__button {
  background: var(--ion-color-primary) !important;
  border: none !important;
  border-radius: 10px;

  &:hover {
    color: #ffffff !important;
  }

}

.margin-0 {
  margin: 0 auto;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: var(--ion-color-primary) !important;
}

.arrow-select {
  background: url("assets/icon/jundo/chevron-down-white.svg") no-repeat;
  background-position: 97% 40%;
  background-size: 4%;

  & .mat-select-arrow {
    visibility: hidden;
  }

}

.mat-dialog-container {
  max-width: 600px;
}


// swiper

.merch-slider .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0 !important;
}

.merch-slider .swiper-pagination-bullet {
  width: 30px;
  height: 7px;
  border-radius: 5px;
  background: grey;
}

.swiper-pagination-bullet-active {
  background: var(--ion-color-primary) !important;
}

.lb-dataContainer {
  position: absolute;
  top: 0 !important;
  right: 0;
}

.mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
  background: #2B2B2B !important;
}

.page-title {
  margin-top: 30px;
}

.spinner-border {
  --bs-spinner-width: 1.6rem;
  --bs-spinner-height: 1.6rem;
}


.container-large {
  padding-left: 50px;
  padding-right: 50px;
}

.cdk-overlay-container {
  z-index: 999999 !important;
}

.comic-dialog {

  .mat-dialog-container {
    width: 1100px !important;
    min-width: 900px;
    border-radius: 8px;
    padding: 0;
  }

  .mat-dialog-content {
    margin: 0;
    padding: 0;
    max-height: 100vh;
  }
}

.account-container {
  margin-top: 100px;
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: #fff !important;
}


.expansion-panel-group {
  background: #373736 !important;
  border-radius: 2px !important;
  box-shadow: none !important;

  & .mat-expansion-panel-header {
    height: 72px !important;
    padding: 0 24px 0 0;
    border-bottom: 2px solid #272726;
  }

  & .card-status-bar-image-box {
    width: 70px;
    height: 70px;
    border-radius: 2px;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }

  & .card-status-bar-info {
    max-width: 78%;
    flex: 1 1 0;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;

    & .card-status-bar-title {
      align-self: stretch;
      word-wrap: break-word;
    }

    & .card-status-bar-description {
      align-self: stretch;
      word-wrap: break-word;
    }

  }

  & .card-status-label {
    word-wrap: break-word;
    flex: auto;
  }

}


.card-status-bar {
  background: #373736;
  height: 72px;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  border-radius: 4px;

  &.chapter-grouped {
    background: #535353;
  }

  &.selected {
    background: #6d6d6d !important;
  }

  & .card-status-bar-image-box {
    width: 70px;
    height: 70px;
    border-radius: 2px;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }

  .card-status-bar-info {
    max-width: 78%;
    flex: 1 1 0;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;

    & .card-status-bar-title {
      align-self: stretch;
      word-wrap: break-word;
    }

    & .card-status-bar-description {
      align-self: stretch;
      word-wrap: break-word;
    }

  }

  .card-status-label {
    word-wrap: break-word;
    flex: auto;
  }

  .card-status-label-coins {
    word-wrap: break-word;
    flex: auto;
    align-items: flex-end;
  }

  .card-status-icon {
    justify-content: flex-end;
    align-items: flex-start;
    display: inline-flex;
    flex: 1;
  }

}

.cart-bg {
  background: rgba(55, 55, 54, 0.70) !important;
  backdrop-filter: blur(10px) !important;
}

.separator {
  opacity: .05;
}

.form-control:focus {
  border: 2px solid var(--ion-color-primary);
  box-shadow: 0 0 0 4px rgba(247, 189, 6, 0.30);
}

.mat-tab-group.mat-tab-group-inverted-header {
  flex-direction: column !important;
}


// -------------------- FORMS STRIPE -------------------- //

.manage-card-box {
  .StripeElement {
    font-family: 'Source Sans Pro', sans-serif !important;
    font-size: inherit;
    height: 50px;
    padding: 16px;
    border: 1px solid #5E5E5E;
    letter-spacing: 0.6px;
    margin: 16px 0;
    border-radius: 5px;
    background: transparent;
  }

  .StripeElement--invalid {
    border: 1px solid #8a2828;
  }
}



